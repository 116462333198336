<template>
  <section class="dtc-main-section">
    <div class="dtc-save-print-btn d-print-none">
      <b-button
        variant="danger"
        @click="printDtxPage"
        class="d-print-none"
        hidden
      >
        <i class="fas fa-print mr-1"></i>列印
      </b-button>
    </div>
    <h3>衛生福利部原住民及離島地區醫事人員養成計畫公費生服務年資核計表</h3>
    <header class="dtx-grid7">
      <div>姓名</div>
      <div>籍 貫</div>
      <div>畢業年月</div>
      <div>醫事人員證書字號</div>
      <div>分發服務單位</div>
      <div>報到日期</div>
      <div>備考</div>
    </header>
    <main class="dtx-grid7-input">
      <div>{{ map.Name }}</div>
      <div>{{ map.County }}</div>
      <div>{{ map.EndYear }}</div>
      <div>{{ map.CertificateNo }}</div>
      <div
        style="text-align:center;line-height:16px; font-size:16px;padding-top:3px;"
      >
        <span class="d-inline-block mt-4 pt-2">{{ map.ExecuteUnit }}</span>
      </div>
      <div>{{ map.EntryDate }}</div>
      <div style="text-align:center;line-height:16px; font-size:12px;">
        {{ map.ShowNote }}
      </div>
    </main>
    <main class="dtx-grid7-input">
      <h5 style="grid-column:1 /-1;" class="pt-2 letter-space">訓 練 年 資</h5>
    </main>

    <main
      class="dtx-grid8"
      v-if="map.TrainingHistoryList && map.TrainingHistoryList.length"
    >
      <div>訓練單位</div>
      <div>職務</div>
      <div>到職日期</div>
      <div>離職日期</div>
      <div>離職原因</div>
      <div class="dtc-sub4-grid" data-msg="應徵召服役時間">
        <div>服役日期</div>
        <div>回職日期</div>
        <div>服役年月日</div>
        <div>未服役因素</div>
      </div>
      <div>實際服務年資</div>
      <div>備考</div>
    </main>
    <main
      class="dtx-grid8-input"
      v-for="(item, i) in map.TrainingHistoryList"
      :key="i"
    >
      <div>{{ item.HospitalName }}</div>
      <div>{{ item.JobTitle }}</div>
      <div>{{ $formatDate(item.StartDate) }}</div>
      <div>{{ $formatDate(item.EndDate) }}</div>
      <div
        style="text-align:center;line-height:16px; font-size:12px;padding-top:3px;"
      >
        {{ item.Reason }}
      </div>
      <div class="dx-grid4">
        <div>{{ $formatDate(item.MilitaryDay) }}</div>
        <div>{{ $formatDate(item.ReturnDay) }}</div>
        <div>{{ $formatDate(item.MilitaryDate) }}</div>
        <div>{{ item.MilitaryReason }}</div>
      </div>
      <div>{{ item.RealService }}</div>
      <div
        style="text-align:center;line-height:16px; font-size:12px;padding-top:3px;"
      >
        {{ item.Remark }}
      </div>
    </main>
    <main class="dtx-grid7-input">
      <h5 style="grid-column:1 /-1;" class="pt-1 letter-space pt-2">
        服 務 年 資
      </h5>
    </main>
    <main
      class="dtx-grid8"
      v-if="map.ServiceHistoryList && map.ServiceHistoryList.length"
    >
      <div>服務單位</div>
      <div>職務</div>
      <div>到職日期</div>
      <div>離職日期</div>
      <div>離職原因</div>
      <div class="dtc-sub4-grid" data-msg="應徵召服役時間">
        <div>服役日期</div>
        <div>回職日期</div>
        <div>服役年月日</div>
        <div>未服役因素</div>
      </div>
      <div>實際服務年資</div>
      <div>備考</div>
    </main>
    <main
      class="dtx-grid8-input dx-larger"
      v-for="(item, i) in map.ServiceHistoryList"
      :key="i"
    >
      <div>{{ item.HospitalName }}</div>
      <div>{{ item.JobTitle }}</div>
      <div>{{ $formatDate(item.StartDate) }}</div>
      <div>{{ $formatDate(item.EndDate) }}</div>
      <div>{{ item.MilitaryReason }}</div>
      <div class="dx-grid4">
        <div>{{ $formatDate(item.MilitaryDay) }}</div>
        <div>{{ $formatDate(item.ReturnDay) }}</div>
        <div>{{ $formatDate(item.MilitaryDate) }}</div>
        <div>{{ item.MilitaryReason }}</div>
      </div>
      <div class="pt-4 pr-1">{{ item.RealService }}</div>
      <div
        style="text-align:center;line-height:16px; font-size:12px;padding-top:3px;"
      >
        {{ item.Remark }}
      </div>
    </main>
    <footer class="dx-f1 mt-4">
      <div data-msg="審查意見" class="f1">機關首長</div>
      <div></div>
      <div data-msg="初核意見" class="f2">人事主任</div>
      <div></div>
      <div>填表人</div>
      <div></div>
    </footer>
    <footer style="margin-top:60px;margin-bottom:60px;">
      <div>備註：</div>
      <div>
        1. 本表由衛生福利部原住民及離島地區醫事人員養成計畫公費生於服務
        年期滿時，自行據實填寫一份，承由服務機關呈報衛生福利部核辦，並分由各級人事主管加註意見。
      </div>
      <div>
        2.
        本表服務年資之核計，依照「原住民及離島地區醫事人員養成計畫公費生服務管理要點」規定辦理。
      </div>
      <div>
        3.
        人事主管審核時應根據其人事命令、兵役命令等文件及有關紀錄詳細查對是否相符？並簽註意見。
      </div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import moment from "moment";
export default {
  name: "totalSpend",
  data() {
    return {
      map: "",
    };
  },

  methods: {
    printDtxPage() {
      window.print();
    },
  },
  async mounted() {
    const { id } = queryString.parse(location.href.split("?")[1]);
    const url = id
      ? "StudentInfo/Get?identifier=" + id
      : "StudentInfo/Get?identifier=V100915130";
    this.map = await window.axios.get(url);
  },
  beforeMount() {
    this.$root.$on("dtc-save-form", () => this.save());
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.letter-space {
  letter-spacing: 4rem;
}
.dtc-title {
  display: inline-block;
  margin-left: -12px;
}
.dtc-main-section {
  //border: 1px solid red;
  max-width: 1100px;
  margin: auto;
  display: grid;
  --dtc-border-color: var(--dark);
  pre {
    color: var(--light);
  }
  input {
    max-height: 30px;
    display: inline-block;
    margin: 0 12px;
    border: none;
    border-bottom: 1px solid var(--dtc-border-color);
    outline: none;
  }
}
.dtx-grid7,
.dtx-grid8,
.dtc-sub4-grid,
.dtx-grid8-input,
.dx-grid4,
.dtx-grid7-input {
  display: grid;
  grid-template-columns: 150px 110px 130px 200px 180px 150px 1fr;
  border: 1px solid var(--dtc-border-color);
  text-align: center;
  > div {
    border-right: 1px solid var(--dtc-border-color);
  }
  > div:last-child {
    border-right: none;
  }
}
.dtx-grid7-input {
  border-top: none;
  > div {
    min-height: 120px;
    line-height: 120px;
  }
}
.dtx-grid8 {
  grid-template-columns: 150px 90px 100px 100px [leave]120px 340px 110px 1fr;
  border-top: none;
  > div {
    height: 120px;
    line-height: 60px;
  }
}
.dtx-grid8-input {
  grid-template-columns: 150px 90px 100px 100px [leave]120px 340px 110px 1fr;
  border-top: none;
  > div {
    min-height: 45px;
    font-size: 14px;
    padding-top: 3px;
  }
}
.dx-larger {
  > div {
    min-height: 65px;
  }
  .dx-grid4 {
    > div {
      min-height: 65px;
    }
  }
}
.dx-grid4 {
  grid-template-columns: repeat(4, 1fr);
  border: none;
  > div {
    min-height: 43px;
    margin-top: -2px;
    padding-top: 2px;
  }
}

.dtc-sub4-grid {
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  border: none;
  align-items: end;
  &::before {
    position: absolute;
    display: block;
    content: attr(data-msg);
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
  }
  > div {
    height: 70%;
    border-top: 1px solid var(--dtc-border-color);
    border-right: 1px solid var(--dtc-border-color);
  }
  > div:last-child {
    border-right: none;
  }
}

.dx-f1 {
  display: grid;
  grid-template-columns: repeat(3, max-content 1fr);
  > div {
    position: relative;
  }
  .f1::before,
  .f2::before {
    position: absolute;
    content: attr(data-msg);
    display: block;
    top: 100%;
  }

  margin-bottom: 30px;
}
.dtc-f2 {
  margin-top: 3rem;
}
h3 {
  margin: auto;
  margin-bottom: 18px;
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    //size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark);
  }
  pre {
    color: black !important;
    outline: none;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
